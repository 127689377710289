var _window, _window$swag, _window$swag$env;
let SENDOSO_URL_PREFIX;
if (typeof window !== 'undefined' && (_window = window) !== null && _window !== void 0 && (_window$swag = _window.swag) !== null && _window$swag !== void 0 && (_window$swag$env = _window$swag.env) !== null && _window$swag$env !== void 0 && _window$swag$env.SENDOSO_URL_PREFIX) {
  SENDOSO_URL_PREFIX = window.swag.env.SENDOSO_URL_PREFIX;
} else if (typeof sendoso_url_prefix !== 'undefined') {
  /** "sendoso_url_prefix" defined with [`webpack.DefinePlugin`](https://webpack.js.org/plugins/define-plugin/) */
  SENDOSO_URL_PREFIX = sendoso_url_prefix;
}
export function urlModifier(url) {
  if (SENDOSO_URL_PREFIX) {
    return `${SENDOSO_URL_PREFIX}${url}`;
  }
  return url;
}
export function urlReplacer(url) {
  if (SENDOSO_URL_PREFIX) {
    return url.replace(SENDOSO_URL_PREFIX, '');
  }
  return url;
}
export const SENDOSO_ORIGIN_SUFIX = SENDOSO_URL_PREFIX;
export const SOCKET_PATH = SENDOSO_URL_PREFIX ? `${SENDOSO_URL_PREFIX}/socket.io/` : null;
export const TRANSPORTS = ['websocket'];
export function getCartLinkId() {
  return '';
}
export function cartUrlModifier(url) {
  return url;
}