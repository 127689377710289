import { urlModifier } from 'swag-client-common/libs/url-modifier.sendoso';
export const APP_ROUTES = {
  CART: urlModifier('/cart'),
  CHECKOUT: urlModifier('/checkout'),
  DELIVERY: urlModifier('/delivery'),
  ACCOUNT_ORDERS: urlModifier('/account/orders'),
  PROFILE_SETTINGS: urlModifier('/account/profile/settings'),
  PROFILE_PASSWORD: urlModifier('/account/profile/password'),
  ALL_SWAG: urlModifier('/collections/all-swag'),
  COMPANY_ORDERS: urlModifier('/company/orders'),
  IN_A_BOX: urlModifier('/in-a-box'),
  HOME: window.swag.env.SENDOSO_URL_PREFIX ? window.swag.env.SENDOSO_URL_PREFIX : '/',
  DISTRIBUTE: urlModifier('/distribute'),
  GIVEAWAY: urlModifier('/gifting'),
  AUTOMATION: urlModifier('/automation'),
  ZAPIER: urlModifier('/zapier'),
  BLOG: urlModifier('/blog'),
  CUSTOM_SWAG: urlModifier('/custom-swag'),
  REVIEWS: urlModifier('/reviews'),
  PAST_WORK: urlModifier('/past-work'),
  CONTACT: urlModifier('/contact'),
  OUR_STORY: urlModifier('/our-story'),
  FAQ: urlModifier('/faq'),
  PRIVACY_POLICY: urlModifier('/privacy-policy'),
  MY_COMPANY_DEPARTMENTS: urlModifier('/my-company/departments'),
  MY_COMPANY_PENDING_ORDERS: urlModifier('/my-company/pending-orders'),
  COLLECTIONS: urlModifier('/collections'),
  SEARCH_RESULTS: urlModifier('/search-results'),
  PRODUCT: urlModifier('/product'),
  LOGIN: urlModifier('/login'),
  ACCOUNT_SAVED_DESIGNS: urlModifier('/account/saved-designs'),
  FAVORITE_PRODUCTS: urlModifier('/favorite-products'),
  MY_COMPANY: urlModifier('/my-company'),
  KICKSTARTER: urlModifier('/kickstarter'),
  WEWORK: urlModifier('/wework'),
  PATAGONIA: urlModifier('/patagonia'),
  SET_PASSWORD: urlModifier('/set-password'),
  VERIFY_EMAIL: urlModifier('/verify-email'),
  FORGOT_PASSWORD: urlModifier('/forgot-password'),
  INVITATION: urlModifier('/invitation'),
  ACCOUNT: urlModifier('/account'),
  ACCOUNT_PROFILE: urlModifier('/account/profile'),
  NOT_FOUND: urlModifier('/not-found'),
  STARBUCKS: urlModifier('/starbucks'),
  OFFICE: urlModifier('/office'),
  TECHSTARS: urlModifier('/techstars'),
  SPRING: urlModifier('/spring'),
  AMERICANUNDERGROUND: urlModifier('/americanunderground'),
  INDUSTRIOUS: urlModifier('/industrious'),
  PRIMOPRINT: urlModifier('/primoprint'),
  TECHSTARS_PRODUCTS: urlModifier('/techstars-products'),
  SPRING_PRODUCTS: urlModifier('/spring-products'),
  INTELLIGENT: urlModifier('/intelligent'),
  KICKSTARTER_CALCULATOR: urlModifier('/kickstarter-calculator'),
  SUPERVISOR_DASHBOARD_ORDER_APPROVAL: urlModifier('/supervisor-dashboard/order-approval'),
  SUPERVISOR_DASHBOARD_ALL_ORDERS: urlModifier('/supervisor-dashboard/all-orders'),
  SUPERVISOR_DASHBOARD_PAYMENT_SETTINGS: urlModifier('/supervisor-dashboard/payment-settings'),
  API_AUTH_REDIRECT: urlModifier('/api/v1/auth/sendoso'),
  TOS: urlModifier('/terms-of-service'),
  CART_LINK: urlModifier('/cart-link/')
};