import robotoBold from 'assets/fonts/roboto/roboto-bold.ttf';
import robotoBlack from 'assets/fonts/roboto/roboto-black.ttf';
import robotoRegular from 'assets/fonts/roboto/roboto-regular.ttf';
import robotoMedium from 'assets/fonts/roboto/roboto-medium.ttf';
import { createFontLinkWithoutPrefix } from './utils';
export const getFonts = () => {
  document.head.prepend(createFontLinkWithoutPrefix(robotoBold));
  document.head.prepend(createFontLinkWithoutPrefix(robotoBlack));
  document.head.prepend(createFontLinkWithoutPrefix(robotoRegular));
  document.head.prepend(createFontLinkWithoutPrefix(robotoMedium));
};